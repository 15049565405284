body {
  background-color: #eee;
}

.App {
  font-size: 1.2rem;
}

.App .main {
  padding-top: 90px;
}

.field.multipleChoice .ui.segment {
  width: 100%;
  padding: 0;
}

.field.multipleChoice .ui.segment .ui.radio.checkbox {
  width: 100%;
  padding: 1em;
}

.list-highlight:hover {
  background-color: #ebf7fd;
}

.imagePreview {
  min-height: 150px;
}

@media print {
  .App > .menu,
  .App .printFilters {
    display: none;
  }

  .App .main {
    padding-top: 10px;
  }

  .presenting {
    font-size: 3rem;
    line-height: 4rem;
  }

  .round:not(:last-of-type).break,
  .presenting .sec.break {
    break-after: page;
  }

  .question {
    break-inside: avoid-page;
  }

  .question.break,
  .answer.break,
  .presenting .sec.break {
    break-before: page;
  }

  .presenting .answer {
    margin-left: 2rem;
  }
  .presenting .answer .label {
    font-size: 3rem;
  }

  .presenting .header .sub.header {
    font-size: 3rem;
  }
}
